import * as React from "react";
import { render } from "react-dom";
import { Refresh } from "./Refresh";
import { Example } from "./Example";
import "./styles.css";

const App = () => {
  const [count, setCount] = React.useState(0);
  return (
    <>
      <Example key={count} />
      <Refresh onClick={() => setCount(count + 1)} />
    </>
  );
};

render(<App />, document.getElementById("root"));
